import "./styles.css";
import { createRoot } from "react-dom/client";
import Participant from "./components/Participant";
import Host from "./components/Host";

function App() {
  const route = window.location.pathname;

  switch (route) {
    case "/participant":
      return (
        <main>
          Participant page.
          <Participant />
        </main>
      );
    case "/host":
      return (
        <main>
          Host page.
          <Host />
        </main>
      );
    default:
      return;
  }
}

createRoot(document.getElementById("app")!).render(<App />);
