import { useAtomValue } from "jotai";
import { memo, useEffect } from "react";
import { getActx, type RTCDataChannelAtom } from "../atoms";

export const Oscillator = memo(function Oscilloscope({
  dataChannelAtom,
}: {
  dataChannelAtom: RTCDataChannelAtom;
}) {
  const aCtx = useAtomValue(getActx)!;
  const dataChannel = useAtomValue(dataChannelAtom)

  useEffect(() => {
    const oGain = aCtx.createGain()
    oGain.connect(aCtx.destination)

    const eventHandler = (evt: MessageEvent) => {
      const [gain, frequency] = evt.data.split(",")
      console.log(gain,frequency)
      const oscillator = aCtx.createOscillator();
      oscillator.type = "square";
      oscillator.frequency.value = parseFloat(frequency);
      oGain.gain.value = parseFloat(gain)
      oscillator.connect(oGain);
      oscillator.start(aCtx.currentTime);
      oscillator.stop(aCtx.currentTime + 1);

    };

    dataChannel.addEventListener("message", eventHandler);

    return () => dataChannel.removeEventListener("message", eventHandler);
  }, []);

  return <div>Oscilloscope</div>;
});
