import { useAtomValue, useSetAtom } from "jotai";
import {
  callsSessionAtom,
  createOutDataChannel,
  outDataChannel,
} from "../atoms";
import { useReducer, type FormEventHandler, type MouseEventHandler } from "react";

export default function Participant() {
  const session = useAtomValue(callsSessionAtom);
  const dc = useAtomValue(outDataChannel);
  const createDc = useSetAtom(createOutDataChannel);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  if (!session) return <div>no session</div>;

  if (!dc) {
    createDc(
      {
        location: "local",
        dataChannelName: "channel-one",
      },
    );
    return <div>no dc</div>;
  }

  const handleClick: MouseEventHandler = (e) => {
    const target = e.target as HTMLDivElement

    const rect = target.getBoundingClientRect()
    const gain = ((e.clientX - rect.left) / rect.width)
    const frequency = ((e.clientY - rect.top) / rect.height) * 22_050 + 20

    dc.send(`${gain},${frequency}`);
  };

  return (
    <div style={{width: "90vw", height:"80vh", backgroundColor: "beige"}} onClick={handleClick}>
      
    </div>
  );
}
