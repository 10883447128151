import { useAtomValue, useSetAtom, useAtom } from "jotai";
import {
  callsSessionAtom,
  socketAtom,
  addHostDataChannel,
  startActxAtom,
  getActx,
} from "../atoms";
import { useEffect } from "react";
import Oscillators from "./Oscillators";
export default function Host() {
  const socket = useAtomValue(socketAtom);
  const session = useAtomValue(callsSessionAtom);
  const addDc = useSetAtom(addHostDataChannel);
  const createActx = useSetAtom(startActxAtom);
  const aCtx = useAtomValue(getActx);

  useEffect(() => {
    if (!session) return;

    const onMessage = (evt: MessageEvent) => {
      const data = JSON.parse(evt.data);
      switch (data.type) {
        case "hostGet":
          const { dataChannels } = data;
          dataChannels.forEach((dc) => {
            // if there is no id there is an errorcode property
            if (!dc.id) return;

            const subscriptionDc = session?.peerConnection.createDataChannel(
              "channel-one-subscribed",
              {
                negotiated: true,
                id: dc.id,
              }
            );
            addDc(subscriptionDc);
          });
          return;
        case "newStream":
          const { dataChannels: dc } = data;
          const subscriptionDc = session?.peerConnection.createDataChannel(
            "channel-one-subscribed",
            {
              negotiated: true,
              id: dc[0].id,
            }
          );
          addDc(subscriptionDc);
          return;
        case "disconnectedStream":
          // need to find a way to uniquely identify each data channel so I can call close() on them
          // and remove them
          console.log(data);
          return;
        default:
          break;
      }
    };
    socket.addEventListener("message", onMessage);

    return () => socket.removeEventListener("message", onMessage);
  }, [socket, session, addDc]);

  if (!session) return <div>no session</div>;

  if (!aCtx) {
    return (
      <>
        <button
          onClick={() => {
            createActx();
            socket.send(
              JSON.stringify({ type: "hostGet", sessionId: session.sessionId })
            );
          }}
        >
          start
        </button>
      </>
    );
  }

  return (
    <>
      <Oscillators />
    </>
  );
}
