import { useAtomValue } from "jotai";
import { hostDataChannelAtoms } from "../atoms";
import { Oscillator } from "./Oscillator";

export default function Oscillators() {
  const dcs = useAtomValue(hostDataChannelAtoms);

  return (
    <>
      {dcs.map((RTCDataChannelAtom) => (
        <Oscillator
          key={`${RTCDataChannelAtom}`}
          dataChannelAtom={RTCDataChannelAtom}
        />
      ))}
    </>
  );
}
