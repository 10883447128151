import type { PrimitiveAtom } from "jotai";
import { atom } from "jotai";
import PartySocket from "partysocket";
import { act } from "react";

interface CallsSession {
  peerConnection: RTCPeerConnection;
  sessionId: string;
}

export type RTCDataChannelAtom = PrimitiveAtom<RTCDataChannel>;

const createRTCDataChannelAtom = (DataChannel: RTCDataChannel) =>
  atom<RTCDataChannel>(DataChannel);

const socket = new PartySocket({
  room: "experience",
  host:
    process.env.NODE_ENV === "development"
      ? "localhost:1999"
      : "first.lourendotco.partykit.dev",
});

const _actxAtom = atom<AudioContext | null>(null);

export const startActxAtom = atom(null, (get, set) => {
  if (!get(_actxAtom)) {
    const actx = new AudioContext();
    set(_actxAtom, actx);
  }
});

export const getActx = atom((get) => get(_actxAtom));

export const socketAtom = atom(socket);

export const callsSessionAtom = atom<CallsSession | null>(null);
callsSessionAtom.onMount = (set) => {
  (async () => {
    const createCallsSession = new Promise((res, rej) => {
      setTimeout(() => rej("socket timeout"), 5000);

      const getRemoteSessionDescriptionHandler = (evt: MessageEvent<any>) => {
        const data = JSON.parse(evt.data);
        if (data.type === "newCalls") {
          socket.removeEventListener(
            "message",
            getRemoteSessionDescriptionHandler
          );
          res(data);
        }
      };

      socket.addEventListener("message", getRemoteSessionDescriptionHandler);
    });

    const peerConnection = new RTCPeerConnection({
      iceServers: [
        {
          urls: "stun:stun.cloudflare.com:3478",
        },
      ],
      bundlePolicy: "max-bundle",
    });

    const dc = peerConnection.createDataChannel("server-events");

    await peerConnection.setLocalDescription(
      await peerConnection.createOffer()
    );

    socket.send(
      JSON.stringify({
        type: "newCallsSession",
        localDescription: peerConnection!.localDescription,
      })
    );

    const connected = new Promise((res, rej) => {
      setTimeout(() => rej("connected timeout"), 5000);
      const iceConnectionStateChangeHandler = () => {
        if (peerConnection!.iceConnectionState === "connected") {
          peerConnection!.removeEventListener(
            "iceconnectionstatechange",
            iceConnectionStateChangeHandler
          );
          res(undefined);
        }
      };
      peerConnection!.addEventListener(
        "iceconnectionstatechange",
        iceConnectionStateChangeHandler
      );
    });

    const { sessionId, sessionDescription } = (await createCallsSession) as {
      sessionId: string;
      sessionDescription: RTCSessionDescriptionInit;
    };

    await peerConnection!.setRemoteDescription(
      sessionDescription as RTCSessionDescriptionInit
    );
    await connected;

    set({ sessionId, peerConnection });
  })();
};

export const outDataChannel = atom<RTCDataChannel | null>(null);

export const createOutDataChannel = atom(null, async (get, set, update) => {
  const { sessionId, peerConnection } = get(callsSessionAtom)!;
  const socket = get(socketAtom);

  const getDataChannels = new Promise((res, rej) => {
    setTimeout(() => rej("socket timeout"), 5000);

    const getDataChannelsHandler = (evt: MessageEvent<any>) => {
      const data = JSON.parse(evt.data);
      if (data.type === "newDC") {
        socket.removeEventListener("message", getDataChannelsHandler);
        res(data);
      }
    };

    socket.addEventListener("message", getDataChannelsHandler);
  });

  socket.send(
    JSON.stringify({ type: "newDC", sessionId, dataChannels: [update] })
  );

  const { dataChannels } = (await getDataChannels) as {
    dataChannels: any[];
  };

  dataChannels.forEach((dc) => {
    const dataChannel = peerConnection.createDataChannel("channel-one", {
      negotiated: true,
      id: dc.id,
    });
    if (update.location === "remote") {
      set(addHostDataChannel, dataChannel);
    } else {
      set(outDataChannel, dataChannel);
    }
  });
});

export const hostDataChannelAtoms = atom<RTCDataChannelAtom[]>([]);

export const addHostDataChannel = atom(
  null,
  (_, set, update: RTCDataChannel) => {
    const RTCDataChannelAtom = createRTCDataChannelAtom(update);
    set(hostDataChannelAtoms, (prev) => [...prev, RTCDataChannelAtom]);
  }
);
